.element_background_color {
  background-color: rgba(221, 240, 251, 0.5);
}

.user {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  width: 180px;
  flex-direction: row;
  justify-content: space-between;
}