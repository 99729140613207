* {
  overflow: hidden;
  cursor: default;
  user-select: none;
}

.main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px - 24px);
  min-height: calc(320px - 80px - 24px); 
  min-width: 340px;
  align-items: center;
  z-index: 1;
}

.h2 {
  margin-bottom: 0;
  color: #5e5e5e;
  font-family: Helvetica, 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.h2 svg:after { 
  content: '\A0'; 
}

.control_panel {
  position: fixed;
  width: 320px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.control_panel_index {
  z-index: 1;
}

.rotary_switch {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.toggle_switch {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.filmstrip {
  width: 320px;
  height: 160px;
  z-index: 0;
  border-radius: 8px;
}

.element_background_color {
  background-color: rgba(221, 240, 251, 0.5);
}

.animation {
  animation-name: text;
  animation-duration: .2s; 
  animation-delay: .3s; 
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

@keyframes text {
  0% {
    opacity: 0;
  }
}