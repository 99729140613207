.header {
  display: flex;
  justify-content: center;
}

.panel {
  display: flex;
  width: 920px;
  min-width: 320px;
  margin: 18px 0 18px 0;
  align-items: center;
}

.stopper {
  height: 44px;
  width: 10px;  
}

.mechanicel {
  margin-right: auto;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding-left: 13px;
  padding-right: 13px;
  align-items: center;
  color: #5e5e5e;
  font-family: Helvetica, 'Open Sans', sans-serif;
  font-size: 25px;
  font-weight: 100;
  text-decoration: none;
}

.cel {
  color: color(srgb 0.4522 0.5938 0.7756);
}

.archive {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

.element_background_color {
  background-color: rgba(221, 240, 251, 0.5);
}
